<template>
    <div id="app_sign_import_id">
        <!-- 多媒体消息导入签名 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="true" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>多媒体消息导入签名</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form :model="form_data" label-width="120px" style="margin-bottom: 12px;">

                        <el-form-item label="导入文件">
                            <!-- show-file-list:是否显示已上传文件列表  默认值 true
                            multiple:是否支持多选文件 
                            data:上传时附带的额外参数
                            auto-upload	是否在选取文件后立即进行上传
                            with-credentials 是否支持发送 cookie 凭证信息  true:支持
                            -->
                            <el-upload ref="uploadFile" style="display: inline-block;"
                                    :action="upload_url" :multiple="true" :limit="1" :auto-upload="false"
                                    :with-credentials="true"
                                    accept=".xls,.xlsx"

                                    :on-success="uploadResponseResult"
                                    :on-error="uploadResponseResultFail"

                                    :file-list="fileList"
                                    
                                    :on-change="uploadChange"
                                    :on-remove="uploadChange"
                                    :before-upload="beforeAvatarUpload"
                                    >
                                <el-button size="mini" type="primary" plain @click="upload()">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">
                                    支持xls格式、xlsx格式，且上传文件大小限制最高4M
                                    <div>
                                        导入文件示例：<br/>
                                        <img src="@/assets/img/media/import_file.png"/>
                                    </div>
                                </div>
                                
                            </el-upload>
                        </el-form-item>

                        <el-form-item label="实际发送企业">
                            <el-select v-model="form_data.user_company_id" placeholder="请从公司列表中选择实际发送的企业" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in signCompanyList"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>


                </el-row>

            </el-row>


        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';
import {
    getItem
} from '../../api/tool.js';

export default {
    props:{
        prop_change_i: 0,
        prop_channel_id: '',//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //请求接口-获取签约公司列表
                    this.getSignCompanyList();
                }, 1);
            }
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            channel_id : 0,//通道id

            signCompanyList:[],//签约公司列表

            form_data:{
                user_company_id:'',//实际发送企业
            },

            upload_url:"",//上传url
            fileList:[],//文件列表
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            this.channel_id = this.$route.params.id;
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.fileList = [];
        },
        //请求接口-获取签约公司列表
        getSignCompanyList(){
            API.CompanyServlet({
                param: "selList",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.signCompanyList = res.list;//id + company_name
                    // var obj = {"id":"","company_name":"请选择"}
                    // this.signCompanyList.unshift(obj);//数组第一个位置插入元素
                }
            });
        },
        //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
        beforeAvatarUpload(file) {
            const imgType = file.name.endsWith('.xls')||file.name.endsWith('.xlsx')||file.name.endsWith('.XLS')||file.name.endsWith('.XLSX');
            const isLt2M = file.size / 1024  < 300;

            if (!imgType) {
                this.$message.error('上传文件只能是 xls或xlsx 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 4M!');
            }
            if(imgType && isLt2M){
                return new Promise((resolve, reject) => {
                    // 拼接上传url
                    // 此处动态配置action URL
                    this.upload_url = API.getInterfaceUrl()+"/MediaSignServlet?param=importMediaSignCUCC&session="+getItem("qxt_session")
                    +"&channel_id="+this.channel_id+"&user_company_id="+this.form_data.user_company_id
                    +"&callback="+( new Date().getTime()+ "_" + Math.ceil(Math.random() * 1000) );
                    // console.log(this.upload_url);
                    // dom上传地址更新完成后，触发上传
                    this.$nextTick(() => resolve());
                });
            }else{
                return imgType && isLt2M;
            }
        },
         //上传 - 文件上传或从列表中移除时触发 
        uploadChange(file, fileList){
            // console.log("文件上传或从列表中移除时触发 "+JSON.stringify(fileList));
            this.fileList = fileList;
        },

        //上传 按钮触发方法
        upload(){
            console.log("上传 按钮触发方法");
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            // this.upload_param:{},//上传带的额外参数
        },
        //上传成功响应结果
        uploadResponseResult(res, file, fileList) {
            //response这个
            // console.log("上传响应结果res:"+JSON.stringify(res));
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            if(res){
                // res = JSON.parse(res);
                if(res.recode == 0){
                    var duration = 5000;
                    if(API.isEmtry(res.text)){
                        res.text = "恭喜您，一共有"+res.succ_num+"个签名提交成功";
                        if(!API.isEmtry(res.fail_str)){
                             res.text +="，以下签名“"+res.fail_str+"”提交失败";
                        }
                    }else{
                        duration = 10000;
                    }
                    // this.$message.error(res.text,{duration:duration});
                    if(!API.isEmtry(res.fail_str)){
                        this.$message.error({
                            showClose: true,
                            message: res.text,
                            duration:duration,
                            type: 'success'
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: res.text,
                            duration:duration,
                            type: 'success'
                        });
                    }
                    this.dialog_visible = false;//弹出框是否可见   false:不可见
                    this.$emit("add_sign", "");
                }else{
                    this.$message.error(res.text);
                }
            }else{
                 this.$message.error("文件上传失败，请稍后再试哦");
            }    
        },
        
        //上传失败响应结果
        uploadResponseResultFail(err, file, fileList){
            console.log("上传失败响应结果");
            this.$message.error("文件上传失败了，请稍后再试哦");
        },

        //提交
        onSubmit(){
            // console.log("数据提交 file_num:"+this.fileList.length+"\t"+JSON.stringify(this.fileList));
            if(API.isEmtry(this.form_data.user_company_id)){
                this.$message.error("请从公司列表中选择实际发送的企业");
                return;
            }
            if(this.fileList.length == 0){
                this.$message.error("请选择文件");
            }else{
                this.$refs.uploadFile.submit();
            }
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>